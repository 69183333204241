import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid } from "@material-ui/core"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Stranica nije pronađena" />
        <Grid
          md={12}
          style={{ height: "12vh", background: "#000", width: "100%" }}
        />
        <center>
          <h2>O-ooo!</h2>
          <h3>Stranica koju tražiš je nestala...</h3>
          <h2>
            <a href="https://ciklo-sport.hr/">
              Vrati se na početnu i probaj ponovo.
            </a>
          </h2>
        </center>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
